export default [
  {
    key: 'name',
    label: 'field.name',
    rules: 'required',
    type: 'text',
  },
  {
    key: 'phone',
    label: 'field.phoneNumber',
    rules: 'required',
    type: 'tel',
  },
  // {
  //   key: 'email',
  //   label: 'field.email',
  //   rules: 'required|email',
  //   type: 'text',
  // },
  {
    label: 'breadcrumb.accountInfomration',
    icon: 'LockIcon',
    type: 'divider',
  },
  {
    key: 'roles',
    label: 'field.role',
    rules: 'required',
    type: 'nAsynMultiSelection',
    repository: 'role',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'perChannel',
    label: 'field.channel',
    rules: '',
    type: 'nAsynMultiSelection',
    repository: 'channel',
    selectionKey: 'id',
    selectionLabel: 'channelName',
  },
  // {
  //   key: 'perChannel',
  //   label: 'field.channel',
  //   rules: '',
  //   type: 'NMultiSelection',
  //   options: [
  //     {
  //       value: 1,
  //       label: 'Channel 1',
  //     },
  //     {
  //       value: 2,
  //       label: 'Channel 2',
  //     },
  //     {
  //       value: 3,
  //       label: 'Baccarat 1',
  //     },
  //     {
  //       value: 4,
  //       label: 'Baccarat 2',
  //     },
  //   ],
  // },
  {
    key: 'username',
    label: 'field.username',
    rules: 'required',
    type: 'text',
    cols: 12,
  },
  {
    key: 'password',
    name: 'new-password',
    label: 'field.password',
    rules: 'required|min:6',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'retypePassword',
    label: 'field.retypePassword',
    rules: 'required|confirmed:password',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'isEnable',
    label: 'status.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
  {
    key: 'isReadonly',
    label: 'status.readonly',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
  {
    key: 'searchUser',
    label: 'general.search',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
  {
    key: 'searchState',
    label: 'field.searchAN',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
]
